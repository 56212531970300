import {checkAuth} from '../context/actions';

export async function AuthRoute(dispatch,props){	
	try{
		const res = await checkAuth(dispatch)
		if(res.data.status === true){
			if( props.location.pathname === "/"){
				props.history.replace("/home")	
			}
		}else{
			if(props.location.pathname !== "/"){
				props.history.replace("/")
			}
		}
	}catch(err){
		throw err
	}
}