import React from 'react';
import {AuthContext} from '../../context/context';

function OnlineNavigation(){
	const {auth} = React.useContext(AuthContext)
	if(!auth.isLogged && auth.firstLoad)
	{
		return(
			<div>
				<nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
					<h3 className="navbar-brand bg-dark">KasiBeat - Admin</h3>
				</nav>
			</div>
		)
	}else{
		return(
			<div>
			</div>
		)
	}
}

export default OnlineNavigation;