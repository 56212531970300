import React,{useReducer} from 'react';
import {authReducer,brandReducer,productReducer} from './reducers';
import {authStates,brandStates,productStates} from './states';

export const AuthContext = React.createContext();
export const BrandContext = React.createContext();
export const ProductContext = React.createContext();

export function AuthProvider({children}){
	const [auth,authDispatch] = useReducer(authReducer,authStates)

	return(
		<AuthContext.Provider value={{auth,authDispatch}}>
			{children}
		</AuthContext.Provider>
	);
}

export function BrandProvider({children}){
	const [brand,brandDispatch] = useReducer(brandReducer,brandStates)

	return(
		<BrandContext.Provider value={{brand,brandDispatch}}>
			{children}
		</BrandContext.Provider>
	);
}

export function ProductProvider({children}){
	const [product,productDispatch] = useReducer(productReducer,productStates)

	return(
		<ProductContext.Provider value={{product,productDispatch}}>
			{children}
		</ProductContext.Provider>
	);
}