import {authStates,brandStates,productStates} from './states';

export function authReducer(state,action){
	switch(action.type){
		case 'SET_LOGIN':
			return{
				...authStates,
				isLogged:true,
				admin:action.payload.data.admin
			}
		case 'SET_LOGOUT':
			return{
				...authStates,
				isLogged:false,
				admin:null
			}
		case 'SET_FIRST_LOAD':
			return{
				...authStates,
				firstLoad:true
			}
		case 'CHECK_LOGIN':
			if(action.payload.data.status === true){
				if(!authStates.isLogged){
					return{
						...authStates,
						firstLoad:true,
						isLogged:true,
						admin:action.payload.data.admin
					}
				}
			}else{
				return{
					...authStates,
					firstLoad:true,
					isLogged:false,
					admin:null
				}
			}
			break;
		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
}

export function brandReducer(state,action){
	switch(action.type){
		case 'SET_BRANDS':
			return{
				...brandStates,
				brands:action.payload.data.businesses
			}
		case 'SET_BRAND':
			return{
				...brandStates,
				brand:action.payload.data.business
			}
		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
}

export function productReducer(state,action){
	switch(action.type){
		case 'SET_PRODUCTS':
			return{
				...productStates,
				products:action.payload.data.products,
			}
		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
}