import React from 'react';
import {Route} from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import Brand from './components/Brand';
import Products from './components/Products';
import OnlineNavigation from './components/navigation/Online';
import OfflineNavigation from './components/navigation/Offline';
import {AuthProvider,BrandProvider,ProductProvider} from './context/context';

function App(){
  return (
    <AuthProvider>
      <OfflineNavigation/>
      <OnlineNavigation/>
      <div>
      <Route exact path="/" component={Login}/>
      <BrandProvider>
        <Route path="/home" component={Home}/>
        <Route path="/brand/:id" component={Brand}/>
        <ProductProvider>
          <Route path="/products/:id" component={Products} />
        </ProductProvider>
      </BrandProvider>
      </div>
    </AuthProvider>
  )    
}

export default App;