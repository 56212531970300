import axios from 'axios';
//const BASE_URL = 'http://localhost:8000/api';
const BASE_URL = 'https://www.api.kasibeat.com/api';

export async function login(dispatch,data){
	try{
		const res = await axios.post(BASE_URL+'/admin_login',{username:data.username,password:data.password});
		if(res.data.status === "success"){
			localStorage.setItem('token',res.data.token);
		}
		dispatch({type:'SET_LOGIN',payload:res})
		return res
	}catch(err){
		throw err
	}
}

export async function logout(dispatch,data){
	try{
		let res;
		if(data.all_devices){
		    res = await axios.post(BASE_URL+'/admin_logout',{token:localStorage.getItem('token'),all_devices:1})
		}else{
			res = await axios.post(BASE_URL+'/admin_logout',{token:localStorage.getItem('token')})
		}
		localStorage.removeItem('token')
		dispatch({type:'SET_LOGOUT'});
		return res
	}catch(err){
		console.log(err)
		throw err
	}
}

export async function checkAuth(dispatch){
	try{
		const res = await axios.get(BASE_URL+'/auth_admin/'+localStorage.getItem('token'))
		dispatch({type:'CHECK_LOGIN',payload:res});			
		return res
	}catch(err){
		throw err
	}
}

export async function addBrand(data){
	try{
		const name = data.name
		const founded = data.founded
		const res = await axios.post(BASE_URL+'/add_no_account_business',{name,founded,token:localStorage.getItem('token')})
		return res
	}catch(err){
		throw err
	}
}

export async function showBrands(dispatch){
	try{
		const res = await axios.get(BASE_URL+'/no_account_businesses')
		dispatch({type:'SET_BRANDS',payload:res});
		return res
	}catch(err){
		throw err
	}
}

export async function showBrand(dispatch,data){
	try{
		const res = await axios.get(BASE_URL+'/no_account_business/'+data.id)
		dispatch({type:'SET_BRAND',payload:res});
		return res
	}catch(err){
		throw err
	}
}

export async function addBusinessProfile(data){
	try{
		const res = await axios.post(BASE_URL+'/add_business_profile',data);
		return res;
	}catch(err){
		throw err;
	}
}

export async function products(dispatch,business){
	try{
		const res = await axios.get(BASE_URL+`/no_account_business_all_products/${business}`);
		dispatch({type:'SET_PRODUCTS',payload:res});
		return res;
	}catch(err){
		throw err;
	}
}

export async function addProduct(data){
	try{
		const res = await axios.post(BASE_URL+`/no_account_business_add_product`,data)
		return res;
	}catch(err){
		throw err;
	}
}