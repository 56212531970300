import React from 'react';
import {Link,useHistory} from 'react-router-dom';
import {AuthContext} from '../../context/context';
import {logout} from '../../context/actions';
import '../../css/Online.css';

function OnlineNavigation(){
	const history = useHistory();
	const {auth,authDispatch} = React.useContext(AuthContext);

	const handleLogout = async () =>{
		const all_devices = 1;
		try{
			await logout(authDispatch,{all_devices});
			history.replace("/")
		}catch(err){
			console.log(err)
		}
	}

	if(auth.isLogged && auth.firstLoad)
	{
		return(
			<div className="mb-content">
				<nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
					<Link className="navbar-brand bg-dark" to="/home">KasiBeat - Admin</Link>
					<button className="btn btn-success ml-auto" onClick={handleLogout}>LOGOUT</button>
				</nav>
			</div>
		)
	}else{
		return(
			<div>
			</div>
		)
	}
}

export default OnlineNavigation;