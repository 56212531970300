import React,{useState,useEffect} from 'react';
import {AuthRoute} from '../helpers/authRoute';
import {login} from '../context/actions';
import {AuthContext} from  '../context/context';
import '../css/Login.css';

function Login(props){
	const {auth,authDispatch} = React.useContext(AuthContext);
	useEffect(()=>{
		AuthRoute(authDispatch,props);
	},[authDispatch,props])
	
	const [username,setUsername] = useState("");
	const [password,setPassword] = useState("");
	const [loading,setLoading] = useState(false);
	const [error,setError] = useState("");

	const handleLogin = async (e) =>{
		e.preventDefault();
		setError("")
		setLoading(true);
		try{
			if(!username || !password){
				throw new Error("Fill in all fields")
			}

			await login(authDispatch,{username,password});
			setLoading(false);
			setUsername("");
			setPassword("");
			props.history.replace('/home');
		}catch(err){
			setLoading(false);
			if(err.response){
				setError(err.response.data.status)
			}else{
				setError(err.message)
			}
		}
	}

	if(!auth.isLogged && auth.firstLoad){
		return(
			<div className="mt-fifth ml-fifth">
				<div className="container">
					<h1 className="font-weight-light ml-2">Login</h1>
					{error &&
						<div className="alert alert-danger login-alert" >
							{error}
						</div>
					}
					<div className="row">
						<div className="col-sm-offset-2 col-sm-8">
							<form id="login" className="form-horizontal">
								<div className="form-group">
									<label className="col-sm-2 control-label" htmlFor="username">Username</label>
									<div className="col-sm-10">
										<input className="form-control" value={username} onChange={e => setUsername(e.target.value)} type="text" id="username" placeholder="Username" />
									</div>				
								</div>
								<div className="form-group">
									<label className="col-sm-2 control-label" htmlFor="password">Password</label>
									<div className="col-sm-10">
										<input className="form-control" type="password" value={password} onChange={e => setPassword(e.target.value)} id="password" placeholder="Password" />
									</div>				
								</div>
								<div className="form-group">
									<div className="col-sm-offset-2 col-sm-10">
										{!loading?
											(<button className="btn btn-success btn-lg" type="submit" onClick={handleLogin}>Login</button>):
											(<button className="btn btn-primary btn-lg disabled" type="submit" disabled={loading}>Loading...</button>)
										}
									</div>
								</div>	
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}else{
		return(
			<div>
			</div>
		)
	}
}

export default Login;