export const authStates ={
	firstLoad:false,
	isLogged:false,
	admin:null
}

export const brandStates ={
	brands:[],
	brand:{},
}

export const productStates = {
	product:{},
	products:[]
}