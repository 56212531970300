import React,{useEffect,useState} from 'react';
import {AuthRoute} from '../helpers/authRoute';
import {AuthContext,BrandContext} from  '../context/context';
import {addBrand,showBrands} from '../context/actions';

function Home(props){
	const {auth,authDispatch} = React.useContext(AuthContext);
	const {brand,brandDispatch} = React.useContext(BrandContext)

	useEffect(()=>{
		AuthRoute(authDispatch,props);
	},[authDispatch,props]);

	useEffect(() =>{
		showBrands(brandDispatch);
	},[brandDispatch]);

	const [business,setBusiness] = useState("");
	const [founded,setFounded] = useState("");
	const [loading,setLoading] = useState(false);
	const [error,setError] = useState("");

	const handleAddBrand = async (e) =>{
		e.preventDefault();
		setError("");
		setLoading(true);

		try{
			if(!founded || !business){
				throw new Error('Fill in all fields');
			}
			const name = business
			await addBrand({name,founded}).then(res=>{
				showBrands(brandDispatch).then(res=>{
					/*Do nothing*/
				}).catch(err=>{
					throw err
				})
			}).catch(err=>{
				throw err
			})
			setLoading(false);
			setFounded("");
			setBusiness(""); 
		}catch(err){
			setLoading(false);
			if(err.response){
				setError(err.response.data.status)
			}else{
				setError(err.message)
			}
		}
	}

	const goToBrand = (e,brand) =>{
		e.preventDefault();
		props.history.push('/brand/'+brand)
	}

	const products = (e,brand) =>{
		e.preventDefault();
		props.history.push('/products/'+brand)
	}

	if(auth.isLogged && auth.firstLoad){
		return(
			<div className="mt-5 container">
				<div className="d-flex justify-content-center">
					{error &&
						<div className="alert alert-danger">
							{error}
						</div>
					}
				</div>
				<div className="d-flex justify-content-center mb-2">
					<input className="form-control" type="text" value={business}  id="brand" onChange={e => setBusiness(e.target.value)} placeholder="Business/Brand Name" />
				</div>
				<div className="d-flex justify-content-center mb-2">
					<input className="form-control" type="text" value={founded}  id="founded" onChange={e => setFounded(e.target.value)} placeholder="Founding Year" />
				</div>
			    {!loading?
					(
						<div className="d-flex justify-content-center mb-4">
							<button className="btn btn-success" onClick={handleAddBrand}>Add New Brand</button>
						</div>
					):
					(
						<div className="d-flex justify-content-center mb-4">
					    	<div className="spinner-grow text-secondary">
					    	</div>
					    </div>
					)	
				}
				{brand.brands.length?
					(
						<table className="table table-striped">
							<thead>
								<tr>
									<th scope="col">#</th>
									<th scope="col">Business/Brand Name</th>
									<th scope="col">Founded</th>
									<th scope="col">Go To Brand</th>
									<th scope="col">Products/Services</th>
								</tr>
							</thead>
							<tbody>
								{brand.brands.map((data,index)=>
									<tr key={data.name}>
										<th scope="row">{index+1}</th>
										<td>{data.name}</td>
										<td>{data.founded}</td>
										<td>
											<button className="btn btn-success" onClick={(e)=>goToBrand(e,data.id)}>Brand</button>						
										</td>
										<td>
											<button className="btn btn-success" onClick={(e)=>products(e,data.id)}>Products/Services</button>						
										</td>
									</tr>	
								)}
							</tbody>
						</table>
					):
					(
						<h4 className="d-flex justify-content-center">No data</h4>
					)
				}
			</div>
		)
	}else{
		return(
			<div>
			</div>
		)
	}
}

export default Home;