import React,{useState,useEffect} from 'react';
//import {useParams,useLocation} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import {AuthRoute} from '../helpers/authRoute';
import {AuthContext,BrandContext} from  '../context/context';
import {showBrand,addBusinessProfile} from '../context/actions';
import {HOST} from '../helpers/constants';

function Brand(props){
	const {authDispatch} = React.useContext(AuthContext);
	const {brand,brandDispatch} = React.useContext(BrandContext);
	let {id} = useParams();
	
	useEffect(()=>{
		AuthRoute(authDispatch,props);	
	},[authDispatch,props]);
	
	useEffect(()=>{
		showBrand(brandDispatch,{id});
	},[id,brandDispatch]);

	const [businessType,setBusinessType] = useState("");
	const [businessHours,setBusinessHours] = useState("");
	const [address,setAddress] = useState("");
	const [email,setEmail] = useState("");
	const [officeNumber,setOfficeNumber] = useState("");
	const [mobileNumber,setMobileNumber] = useState("");
	const [facebook,setFacebook] = useState("");
	const [whatsapp,setWhatsapp] = useState("");
	const [twitter,setTwitter] = useState("");
	const [instagram,setInstagram] = useState("");
	const [website,setWebsite] = useState("");
	const [description,setDescription] = useState("");
	const [loading,setLoading] = useState(false);
	const [error,setError] = useState("");
	const [picture,setPicture] = useState(null);

	const handleProfileEdit = async (e) =>{
		e.preventDefault();
		setError("");
		try{
			if(businessType || businessHours || address || email || officeNumber || mobileNumber || facebook || whatsapp || twitter || instagram || website || description || picture){
				let formData = new FormData();
				setLoading(true);
				let data = {},properties = [];
				let d;
				if(businessType){
					data = {...data,business_type:businessType};
				}

				if(businessHours){
					data = {...data,business_hours:businessHours};
				}

				if(address){
					data = {...data,address};
				}

				if(email){
					data = {...data,email};
				}

				if(officeNumber){
					data = {...data,office_number:officeNumber};
				}

				if(mobileNumber){
					data = {...data,mobile_number:mobileNumber};
				}

				if(facebook){
					data = {...data,facebook};
				}

				if(whatsapp){
					data = {...data,whatsapp};
				}

				if(twitter){
					data = {...data,twitter};
				}

				if(instagram){
					data = {...data,instagram};
				}

				if(website){
					data = {...data,website};
				}

				if(description){
					data = {...data,description};
				}

				if(picture){
					data = {...data,picture}
				}
				/*
					Extract the keys of the object values
					and push them into an array
					ie. x={name:'Lesego',surname:'Seritili'}
					will be pushed into an array as
					y=['name','surname']
				*/
				for(d in data){
					properties.push(d);
				}

				for(let i = 0; i < properties.length;i++){
					formData.append(properties[i],data[properties[i]])
				}

				formData.append('business_id',id);
				formData.append('token',localStorage.getItem('token'));

				await addBusinessProfile(formData).then(res=>{
					showBrand(brandDispatch,{id}).then(res=>{
						//Do nothing
					}).catch(err=>{
						throw err;
					})

				}).catch(err=>{
					throw err;		
				})
				setLoading(false);
				setBusinessType("");
				setBusinessHours("");
				setAddress("");
				setEmail("");
				setMobileNumber("");
				setOfficeNumber("");
				setFacebook("");
				setInstagram("");
				setTwitter("");
				setWhatsapp("");
				setWebsite("");
				setDescription("");
				setPicture(null);
			}else{
				throw new Error('Fill in at least one field.');
			}
		}catch(err){
			setLoading(false);
			if(err.response){
				setError(err.response.data.status);
			}else{
				setError(err.message);
			}
		}
	}

	const changeLogo = (e) =>{
		e.preventDefault();
		setError("");
		setPicture(null);
		if(e.target.files[0]){
			const maxSize  = 2*1024*1024; //2MB
			const acceptedImageTypes = ["image/jpg","image/jpeg","image/png","image/gif","image/svg"];

			if(acceptedImageTypes.includes(e.target.files[0].type) && e.target.files[0].size <= maxSize)
			{
				setPicture(e.target.files[0]);	
			}else if(e.target.files[0].size > maxSize){
				setError("Image has to be 2MB or less in size");
			}else if(!acceptedImageTypes.includes(e.target.files[0].type)){ 
				setError("File type not supported.");
			}	
		}
	}

	return(
		<div className="container mb-5">
			<div className="d-flex justify-content-center">
					<h2 className="font-weight-light mb-2">Business/Brand - {brand.brand.name}</h2>
			</div>
			<div className="d-flex justify-content-center">
				<h3 className="font-weight-light mb-3">Founded/Est - {brand.brand.founded}</h3>
			</div>
			<div className="d-flex justify-content-center">
				{brand.brand.business_logo?
					(<img src={HOST+brand.brand.business_logo} width="200px" height="100px" alt="BRAND"/>):
					(<div></div>)
				}
			</div>
			<div className="d-flex justify-content-center">
				{error &&
					<div className="alert alert-danger login-alert d-flex justify-content-center" >
						{error}
					</div>
				}
			</div>
			<hr/>
			<div className="d-flex justify-content-center mt-2 mb-2">
				<input accept="image/*" onChange={changeLogo} type="file"/>
			</div>
			<div className="d-flex">
				{brand.brand.business_type?
					(<h5>{brand.brand.business_type}</h5>):
					(<div></div>)
				}
			</div>
			<div className="d-flex justify-content-center">
				<input className="form-control mb-2" type="text" value={businessType}  id="business_type" onChange={e => setBusinessType(e.target.value)} placeholder="Business Type" />
			</div>
			<div className="d-flex">
				{brand.brand.business_hours?
					(<h5>{brand.brand.business_hours}</h5>):
					(<div></div>)
				}
			</div>
			<div className="d-flex justify-content-center">
				<input className="form-control mb-2" type="text" value={businessHours}  id="business_hours" onChange={e => setBusinessHours(e.target.value)} placeholder="Business Hours" />
			</div>
			<div className="d-flex">
				{brand.brand.address?
					(<h5>{brand.brand.address}</h5>):
					(<div></div>)
				}
			</div>
			<div className="d-flex justify-content-center">
				<input className="form-control mb-2" type="text" value={address}  id="address" onChange={e => setAddress(e.target.value)} placeholder="Address" />
			</div>
			<div className="d-flex">
				{brand.brand.email?
					(<h5>{brand.brand.email}</h5>):
					(<div></div>)
				}
			</div>
			<div className="d-flex justify-content-center">
				<input className="form-control mb-2" type="text" value={email}  id="email" onChange={e => setEmail(e.target.value)} placeholder="Email" />
			</div>
			<div className="d-flex">
				{brand.brand.office_number?
					(<h5>{brand.brand.office_number}</h5>):
					(<div></div>)
				}
			</div>
			<div className="d-flex justify-content-center">
				<input className="form-control mb-2" type="text" value={officeNumber}  id="office_number" onChange={e => setOfficeNumber(e.target.value)} placeholder="Office Number" />
			</div>
			<div className="d-flex">
				{brand.brand.mobile_number?
					(<h5>{brand.brand.mobile_number}</h5>):
					(<div></div>)
				}
			</div>
			<div className="d-flex justify-content-center">
				<input className="form-control mb-2" type="text" value={mobileNumber}  id="mobile_number" onChange={e => setMobileNumber(e.target.value)} placeholder="Mobile Number" />
			</div>
			<div className="d-flex">
				{brand.brand.facebook?
					(<h5>{brand.brand.facebook}</h5>):
					(<div></div>)
				}
			</div>
			<div className="d-flex justify-content-center">
				<input className="form-control mb-2" type="text" value={facebook}  id="facebook" onChange={e => setFacebook(e.target.value)} placeholder="Facebook" />
			</div>
			<div className="d-flex">
				{brand.brand.whatsapp?
					(<h5>{brand.brand.whatsapp}</h5>):
					(<div></div>)
				}
			</div>
			<div className="d-flex justify-content-center">
				<input className="form-control mb-2" type="text" value={whatsapp}  id="whatsapp" onChange={e => setWhatsapp(e.target.value)} placeholder="WhatsApp" />
			</div>
			<div className="d-flex">
				{brand.brand.twitter?
					(<h5>{brand.brand.twitter}</h5>):
					(<div></div>)
				}
			</div>	
			<div className="d-flex justify-content-center">
				<input className="form-control mb-2" type="text" value={twitter}  id="twitter" onChange={e => setTwitter(e.target.value)} placeholder="Twitter" />
			</div>
			<div className="d-flex">
				{brand.brand.instagram?
					(<h5>{brand.brand.instagram}</h5>):
					(<div></div>)
				}
			</div>
			<div className="d-flex justify-content-center">
				<input className="form-control mb-2" type="text" value={instagram}  id="twitter" onChange={e => setInstagram(e.target.value)} placeholder="Instagram" />
			</div>
			<div className="d-flex">
				{brand.brand.website?
					(<h5>{brand.brand.website}</h5>):
					(<div></div>)
				}
			</div>
			<div className="d-flex justify-content-center">
				<input className="form-control mb-2" type="text" value={website}  id="website" onChange={e => setWebsite(e.target.value)} placeholder="Website" />
			</div>
			<div className="d-flex">
				{brand.brand.description?
					(<h5>{brand.brand.description}</h5>):
					(<div></div>)
				}
			</div>
			<div className="d-flex justify-content-center">
				<textarea className="form-control mb-2" type="text" value={description}  id="description" onChange={e => setDescription(e.target.value)} placeholder="Description" />
			</div>
			{!loading?
				(<div className="d-flex justify-content-center">	
					<button className="btn btn-success mb-5" onClick={handleProfileEdit}>Edit Profile</button>
				</div>):	
				(<div className="d-flex justify-content-center">
					<div className="spinner-border text-success">	
						<span className="sr-only" >Loading...</span>
					</div>
				</div>)
			}
		</div>
	);
}

export default Brand;