import React,{useEffect,useState} from 'react';
import {useParams} from 'react-router-dom';
import {AuthRoute} from '../helpers/authRoute';
import {AuthContext,BrandContext,ProductContext} from  '../context/context';
import {showBrand,products,addProduct} from '../context/actions';
import {HOST} from '../helpers/constants';

function Products(props){
	const {authDispatch} = React.useContext(AuthContext);
	const {brand,brandDispatch} = React.useContext(BrandContext);
	const {product,productDispatch} = React.useContext(ProductContext);
	let {id} = useParams();

	useEffect(()=>{
		AuthRoute(authDispatch,props);	
	},[authDispatch,props]);
	
	useEffect(()=>{
		showBrand(brandDispatch,{id}).then(res => {
			products(productDispatch,id).then(res=>{
				setPageLoading(false);	
			}).catch(err=>{
				throw err;
			});   
		}).catch(err=>{
			setPageLoading(false);
		});
	},[id,brandDispatch,productDispatch]);

	const [picture,setPicture] = useState("");
	const [productType,setProductType] = useState("");
	const [productName,setProductName] = useState("");
	const [price,setPrice] = useState("");
	const [description,setDescription] = useState("");
	const [error,setError] = useState("");
	const [pageLoading,setPageLoading] = useState(true);
	const [loading,setLoading] = useState(false);

	const handleAddProduct = async (e) =>{
		e.preventDefault();
		setError("");
		try{

			if(picture && productType && productName && price && description){
				if(isNaN(price)){
					throw new Error("Price is not a valid,price should contain only numbers i.e 5 or 5.50")
				}

				let formData = new FormData();
				setLoading(true);
				const data = {
					picture,
					product_type:productType,
					product_name:productName,
					price,
					description
				};
				const properties = [];

				/*
					Extract the keys of the object values
					and push them into an array
					ie. x={name:'Lesego',surname:'Seritili'}
					will be pushed into an array as
					y=['name','surname']
				*/				
				for(let d in data){
					properties.push(d);
				}

				for(let i = 0;i < properties.length;i++){
					formData.append(properties[i],data[properties[i]]);
				}
				formData.append('business_id',id);
				formData.append('token',localStorage.getItem('token'));

				await addProduct(formData).then(res =>{
					setProductName("");
					setProductType("");
					setDescription("");
					setPrice("");
					setPicture("");
					products(productDispatch,id).then(res=>{
						setLoading(false);
					}).catch(err=>{
						throw err;
					})											
				}).catch(err=>{
					throw err
				})
				
			}else{
				throw new Error('Fill in all fields');
			}
		}catch(err){
			setLoading(false);
			if(err.response){
				setError(err.response.data.status);
			}else{
				setError(err.message);
			}
		}
	}

	const productImage = (e) =>{
		e.preventDefault();
		setError("");
		setPicture(null);
		if(e.target.files[0]){
			const maxSize  = 2*1024*1024; //2MB
			const acceptedImageTypes = ["image/jpg","image/jpeg","image/png","image/gif","image/svg"];

			if(acceptedImageTypes.includes(e.target.files[0].type) && e.target.files[0].size <= maxSize)
			{
				setPicture(e.target.files[0]);	
			}else if(e.target.files[0].size > maxSize){
				setError("Image has to be 2MB or less in size");
			}else if(!acceptedImageTypes.includes(e.target.files[0].type)){ 
				setError("File type not supported.");
			}	
		}
	}

	return(
		<div>
		{!pageLoading?
			(<div className="container mb-5">
				<div className="d-flex justify-content-center">
					<h2 className="font-weight-light mb-2">Business/Brand - {brand.brand.name}</h2>
				</div>
				<div className="d-flex justify-content-center">
					<h3 className="font-weight-light mb-3">Founded/Est - {brand.brand.founded}</h3>
				</div>
				<div className="d-flex justify-content-center">
					{brand.brand.business_logo?
						(<img src={HOST+brand.brand.business_logo} width="200px" height="100px" alt="BRAND"/>):
						(<div></div>)
					}
				</div>
				<div className="d-flex justify-content-center">
					{error &&
						<div className="alert alert-danger login-alert d-flex justify-content-center" >
							{error}
						</div>
					}
				</div>
				<hr/>
				<div className="d-flex justify-content-center mt-2 mb-2">
					<input accept="image/*" onChange={productImage} type="file"/>
				</div>
				<div className="d-flex justify-content-center">
					<input className="form-control mb-2" type="text" value={productName}  id="product_name" onChange={e => setProductName(e.target.value)} placeholder="Name" />
				</div>
				<div className="d-flex justify-content-center">
					<input className="form-control mb-2" type="text" value={productType}  id="product_type" onChange={e => setProductType(e.target.value)} placeholder="Type" />
				</div>
				<div className="d-flex justify-content-center">
					<input className="form-control mb-2" type="text"  id="price" value={price} onChange={e => setPrice(e.target.value)} placeholder="Price" />
				</div>
				<div className="d-flex justify-content-center">
					<input className="form-control mb-2" type="text" value={description}  id="description" onChange={e => setDescription(e.target.value)} placeholder="Description" />
				</div>
				{!loading?
					(<div className="d-flex justify-content-center">	
						<button className="btn btn-success mb-5" onClick={handleAddProduct}>Add Product</button>
					</div>):	
					(<div className="d-flex justify-content-center">
						<div className="spinner-border text-success">	
							<span className="sr-only" >Loading...</span>
						</div>
					</div>)
				}
				<hr/>
				<div className="d-flex justify-content-center">
					<h2 className="font-weight-light mb-2">Product(s):</h2>
				</div>
				<div className="d-flex justify-content-center">
					{product.products.length && !pageLoading?
						(
							<table className="table table-striped">
								<thead>
									<tr>
										<th scope="col">#</th>
										<th scope="col">Product</th>
										<th scope="col">Image</th>
										<th scope="col">Price</th>
										<th scope="col">Type</th>
										<th scope="col">Description</th>
										<th scope="col">Edit</th>
										<th scope="col">Delete</th>
									</tr>
								</thead>
								<tbody>
									{product.products.map((data,index)=>
										<tr key={data.id}>
											<th scope="row">{index+1}</th>
											<td>{data.product_name}</td>
											<td><img src={HOST+data.product_picture} width="80px" height="80px" alt="PRODUCT"/></td>
											<td>R {data.price}</td>
											<td>{data.product_type}</td>
											<td>{data.description}</td>
											<td>
												<button className="btn btn-success" >Edit</button>						
											</td>
											<td>
												<button className="btn btn-danger" >Delete</button>						
											</td>
										</tr>	
									)}
								</tbody>
							</table>
						):
						(
							<h4 className="d-flex justify-content-center font-weight-light">No Product(s)</h4>
						)
					}
				</div>
			</div>):(
				<div className="d-flex justify-content-center">
					<div className="spinner-border text-success">	
						<span className="sr-only" >Loading...</span>
					</div>
				</div>
			)
		}
		</div>
	)
}

export default Products;